import { useEffect, useRef, useState } from 'react';
import css from './navbar.module.css';
import LogoImage from '../../assets/logo_final.svg';
import LogoSmallScreen from '../../assets/logo_final_small.svg';
import { FavoritesButton, GetAvatar, HostButton } from './helpers/navbar-functions';
import LanguageSwitcher from '../LanguageSwitcherMobile/LanguageSwitcher';
import classNames from 'classnames';
import { MenuNew } from './helpers/menuNew/menuNew';
import { TopBarSearch } from './helpers/topBarSearch/topBarSearch';
import OldLogo from '../OldLogo/OldLogo';
import { useParams } from 'react-router-dom';
import { removeLanguagePrefix } from '../../util/urlHelpers';

export const Navbar = props => {
  const {
    currentUser,
    location,
    onLogout,
    history,
    currentSearchParams,
    isListingPage,
    bookingDetailsNav,
  } = props;

  const { lang } = useParams();

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  const navbarClassNames = classNames(
    css.navbarContainer,
    removeLanguagePrefix(location.pathname) === '/s' || scrollPosition > 180
      ? css.navbarWhiteBg
      : css.navbarTransparentBg,
    menuOpen ? css.blueBg : ''
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [menuRef]);
  const handleMouseEnter = () => setMenuOpen(true);
  const handleMouseLeave = () => setMenuOpen(false);

  let touchMoveEvent = null;
  let wheelEvent = null;
  let position = 0;
  useEffect(() => {
    if (typeof window !== 'undefined' && window?.document) {
      touchMoveEvent = 'ontouchmove' in document?.documentElement ? 'touchmove' : null;
      wheelEvent = 'onwheel' in document?.createElement('div') ? 'wheel' : 'mousewheel';
      window.addEventListener(wheelEvent, changeCss, false);
      window.addEventListener(touchMoveEvent, changeCss, { passive: true });
    }
  }, []);

  const changeCss = () => {
    if (typeof window !== 'undefined' && window?.document) {
      position = document?.body?.scrollTop
        ? document?.body?.scrollTop
        : position !== 0
        ? position
        : 0;
      setScrollPosition(position);
    }
  };


  return (
    <nav className={navbarClassNames}>
      <div className={css.mainWrapper} onMouseLeave={handleMouseLeave}>
        <div className={css.navContent}>
          <div className={css.socialbnbLogo} data-elbaction="click:click topbar-logo">
            <a href={`/${lang || 'en'}`}>
              {scrollPosition > 180 ||
              (typeof window !== 'undefined' &&
                removeLanguagePrefix(window?.location?.pathname) !== '/') ? (
                <img src={LogoImage} className={css.logoImage} />
              ) : (
                <div className={css.logoImage}>
                  <OldLogo
                    format="white"
                    className={css.logoImage}
                    style={{ marginTop: '-4px', width: '145px' }}
                  />
                </div>
              )}
              {menuOpen ? (
                <OldLogo
                  format="crop"
                  className={css.logoImageSmall}
                  style={{ marginTop: '-4px' }}
                />
              ) : scrollPosition > 180 ||
                (typeof window !== 'undefined' &&
                  removeLanguagePrefix(window?.location?.pathname) !== '/') ? (
                <img src={LogoSmallScreen} className={css.logoImageSmall} />
              ) : (
                <OldLogo
                  format="crop"
                  className={css.logoImageSmall}
                  style={{ marginTop: '-4px', marginLeft: '2px', width: '200px' }}
                />
              )}
            </a>
          </div>
          <div className={menuOpen ? css.hideMainItems : ''} onMouseEnter={handleMouseLeave}>
            <div className={isListingPage ? css.hideForDesktop : ''}>
              <TopBarSearch
                history={history}
                location={location}
                currentSearchParams={currentSearchParams}
                lang={lang}
              />
            </div>
            <div className={isListingPage ? css.hideForMobile : ''}>{bookingDetailsNav}</div>
          </div>
          <div className={css.leftSideWrapper}>
            <HostButton currentUser={currentUser} />

            <FavoritesButton currentUser={currentUser} />
              {(
                <div className={currentUser ? css.languageSwitch : css.languageSwitchGuest}>
                  <LanguageSwitcher />
                </div>
              )
            }

            <div
              className={css.avatarWrapper}
              ref={menuRef}
              onMouseEnter={handleMouseEnter}
              onClick={() => setMenuOpen(prev => !prev)}
            >
              <GetAvatar currentUser={currentUser} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              {menuOpen && (
                <div className={css.menWrapper}>
                  <MenuNew
                    currentUser={currentUser}
                    history={history}
                    onLogout={onLogout}
                    setMenuOpen={setMenuOpen}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
