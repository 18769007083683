import React from 'react';
import css from './NewExperiences.module.css';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import oceanImage from './images/ocean-boat.webp';
import elephantImage from './images/jungle-elephants.webp';
import tribeImage from './images/card-image1.webp';
import impactOfTousim from './images/impactOfTourism.webp';
import callOfForest from './images/callOfForest.webp';
import ExperienceCard from './ExperienceCard';
import { useParams } from 'react-router-dom';

const NewExperiences = () => {
  const cards = [
    {
      id: 1,
      title: <FormattedMessage id="sectionNewExperience.card1.title" />,
      duration: <FormattedMessage id="sectionNewExperience.card1.duration" />,
      description: <FormattedMessage id="sectionNewExperience.card1.description" />,
      price: '2999',
      image: tribeImage,
      link: '/p/ecoexplora-ecuador',
      elwalkerTag: 'lp-now-new Impact Rundreise Ecuador',
    },
    {
      id: 2,
      title: <FormattedMessage id="sectionNewExperience.card2.title" />,
      duration: <FormattedMessage id="sectionNewExperience.card2.duration" />,
      description: <FormattedMessage id="sectionNewExperience.card2.description" />,
      price: '950',
      image: oceanImage,
      link: '/l/ischia-dolphin-project-7-days-6-nights/66c5a193-f9ff-44f7-a963-8ac28ae831b2',
      elwalkerTag: 'lp-now-new Dolphin-und-wal',
    },
    {
      id: 3,
      title: <FormattedMessage id="sectionNewExperience.card3.title" />,
      duration: <FormattedMessage id="sectionNewExperience.card3.duration" />,
      description: <FormattedMessage id="sectionNewExperience.card3.description" />,
      price: '950',
      image: elephantImage,
      link: '/l/tsaurab-valley-eco-camp-14-days-13-nights/672dfacb-896b-4b69-ad23-571f2e990fdb',
      elwalkerTag: 'lp-now-new Elefanten-freiwillgenprojekt',
    },
  ];

  const smallCards = [
    {
      id: 1,
      image: callOfForest,
      link: 'https://www.youtube.com/watch?v=m5vIM6sRsjs&t=85s',
      elwalkerTag: 'lp-now-new Call-of-Forest',
    },
    {
      id: 2,
      image: impactOfTousim,
      link: 'https://www.youtube.com/watch?v=3IaEQJP3MF4&t=231s',
      elwalkerTag: 'lp-now-new Impact-EcoTourism',
    },
  ];

  const { lang } = useParams()

  return (
    <section
      className={css['mission-steps-container']}
      data-elb="section"
      data-elb-section="name:footerMain;position:11"
      data-elbaction="visible:section lp-now-new visible"
    >
      <div className={css['mission-container']}>
        <div className={css['section-header']}>
          <h2 className={css.sectionh2Title}>
            <FormattedMessage id="sectionNewExperiences.sectionTitle" />
          </h2>
          <div className={css['subtitle']}>
            <FormattedMessage id="sectionNewExperiences.sectionDescription" />
          </div>
        </div>
        <div className={css['experiences-grid']}>
          <ExperienceCard exp={cards[0]} isVertical={true} currentLanguage={lang} />

          <div className={css['container']}>
            <div className={css['row']}>
              <ExperienceCard exp={cards[1]} isVertical={false} currentLanguage={lang} />
              <a
                href={smallCards[0].link}
                target="_blank"
                rel="noopener noreferrer"
                className={css['row-image']}
              >
                <img loading="lazy" src={callOfForest} alt="Impact of Tourism" />
              </a>
            </div>

            <div className={css['row']}>
              <a
                href={smallCards[1].link}
                target="_blank"
                rel="noopener noreferrer"
                className={css['row-image']}
              >
                <img loading="lazy" src={impactOfTousim} alt="Call of Forest" />
              </a>
              <ExperienceCard exp={cards[2]} isVertical={false} currentLanguage={lang} />
            </div>
          </div>
          <div className={css['small-cards-container']}>
            <div className={css['row']}>
              <ExperienceCard
                exp={smallCards[0]}
                isVertical={true}
                isSmall={true}
                currentLanguage={lang}
              />
            </div>
            <div className={css['row']}>
              <ExperienceCard
                exp={smallCards[1]}
                isVertical={true}
                isSmall={true}
                currentLanguage={lang}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewExperiences;
