import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const ExperiencesListSkeleton = () => {
  return (
    <div
      className="container"
      style={{
        padding: '20px',
      }}
    >
      <div style={{ display: 'flex', gap: '20px' }}>
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
      </div>
      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
        <Skeleton width={300} height={320} />
      </div>
    </div>
  );
};

export default ExperiencesListSkeleton;
