import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import { ExternalLink, NamedLink } from '../../components';
import Cookies from 'universal-cookie';
import css from './FAQPage.module.css';

const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1080px',
    margin: 'auto',
  },
  accordion: {
    boxShadow: 'none',
  },
  section: {
    borderBottom: '2px solid #353535',
    boxShadow: 'none',
    backgroundImage: "url('https://www.transparenttextures.com/patterns/groovepaper.png')",
    backgroundSize: '400px 240px',
    backgroundRepeat: 'repeat',
  },
}));

export default function FAQPage() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [isShowGeneral, setIsShowGeneral] = useState(false);
  const [isShowTravelers, setIsShowTravelers] = useState(false);
  const [isShowProjects, setIsShowProjects] = useState(false);

  const header1Ref = useRef(null);
  const header2Ref = useRef(null);
  const header3Ref = useRef(null);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // FAQ sections
  const faq1 = Array.from({ length: 5 }, (_, i) => i + 1);
  const faq2 = Array.from({ length: 7 }, (_, i) => i + 6);
  const faq3 = Array.from({ length: 9 }, (_, i) => i + 13);

  const getQA = item => {
    const language = cookies.get('language') === 'en' ? 'en' : 'de';

    if (item === 8) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}?category=forYou`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }
    if (item === 9) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  contactForm: (
                    <ExternalLink href={`https://www.socialbnb.org/${language}/Contact`}>
                      {language === 'en' ? 'contact form' : 'Kontaktformular'}
                    </ExternalLink>
                  ),
                  emailLink: <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }
    if (item === 14) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}/BecomeHostPage`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 15) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  contact: (
                    <ExternalLink href={`https://www.socialbnb.org/${language}/contact`}>
                      <FormattedMessage id={'faq.q' + item + '.link'} />
                    </ExternalLink>
                  ),
                }}
              />
              {/*  */}
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 19) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}/p/agb`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }
    if (item === 19) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}/p/agb`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 20) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}/p/contact`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 21) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
              <ExternalLink href={`https://www.socialbnb.org/${language}/p/contact`}>
                <FormattedMessage id={'faq.q' + item + '.link'} />
              </ExternalLink>
              <FormattedMessage id={'faq.q' + item + '.a2'} />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Accordion
        key={item}
        className={classes.accordion}
        expanded={expanded === 'panel' + item}
        onChange={handleChange('panel' + item)}
      >
        <AccordionSummary
          className={classes.section}
          expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
          aria-controls={'panel' + item + 'bh-content'}
          id={'panel' + item + 'bh-header'}
        >
          <span className={css.heading}>
            <FormattedMessage id={'faq.q' + item + '.q'} />
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            <FormattedMessage id={'faq.q' + item + '.a'} />
          </p>
        </AccordionDetails>
      </Accordion>
    );
  };

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header3Ref.current.getBoundingClientRect().top -
        38 -
        header3Ref.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowProjects) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowProjects]);

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header2Ref.current.getBoundingClientRect().top -
        38 -
        header2Ref.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowTravelers) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowTravelers]);

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header1Ref.current.getBoundingClientRect().top -
        38 -
        header1Ref.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowGeneral) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowGeneral]);

  useEffect(() => {
    window.scroll({
      top: 0.1,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <h2 ref={header1Ref} className={css.FAQheader}>
        <FormattedMessage id="faq.headline1" />
      </h2>

      {isShowGeneral ? (
        <div className={classes.root}>{faq1.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq1.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowGeneral(!isShowGeneral)}>
        <FormattedMessage id={isShowGeneral ? 'faq.showLess' : 'faq.showAll'} />
      </button>

      <h2 ref={header2Ref} className={css.FAQheader}>
        <FormattedMessage id="faq.headline2" />
      </h2>

      {isShowTravelers ? (
        <div className={classes.root}>{faq2.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq2.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowTravelers(!isShowTravelers)}>
        <FormattedMessage id={isShowTravelers ? 'faq.showLess' : 'faq.showAll'} />
      </button>

      <h2 ref={header3Ref} className={css.FAQheader}>
        <FormattedMessage id="faq.headline3" />
      </h2>

      {isShowProjects ? (
        <div className={classes.root}>{faq3.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq3.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowProjects(!isShowProjects)}>
        <FormattedMessage id={isShowProjects ? 'faq.showLess' : 'faq.showAll'} />
      </button>
    </div>
  );
}
