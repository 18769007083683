import { memo, useEffect, useRef, useState } from 'react';
import { categoryConfig } from './categoryConfig';
import { FormattedMessage, useIntl } from 'react-intl';
import PublicationSection from './PublicationSection';

const HeroImage = ({ currentCategory, children, header, css }) => {
  const categoryData = categoryConfig[currentCategory] || categoryConfig.stays;
  const { heroImage, heroText } = categoryData;
  const intl = useIntl();
  const textWrapperRef = useRef(null);

  useEffect(() => {
    if (typeof window === 'undefined') return; // Prevent SSR crash

    let isMounted = true;

    const preloadImages = () => {
      if (!isMounted) return;

      const imagesToPreload = Object.values(categoryConfig)
        .map(({ heroImage }) => heroImage)
        .filter(img => img !== categoryData.heroImage);

      imagesToPreload.forEach(imageUrl => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image'; // Fixed incorrect `as` attribute
        link.href = imageUrl; // Fixed incorrect assignment
        link.crossOrigin = 'anonymous';
        document.head.appendChild(link);
      });
    };

    if (typeof window.requestIdleCallback !== 'undefined') {
      requestIdleCallback(preloadImages, { timeout: 2000 });
    } else {
      setTimeout(preloadImages, 1000);
    }

    return () => {
      isMounted = false;
      document.querySelectorAll('link[rel="preload"][as="image"]').forEach(link => link.remove());
    };
  }, [categoryData.heroImage]);

  const splitInTwoLines = text => {
    const lines = text.split(' ');
    const wordsLengthDivided =
      lines.includes('Family-Friendly') || lines.includes('Inseln') || lines.includes('Tieren')
        ? lines.length / 3
        : lines.includes('Booked') && lines.includes('Experiences')
        ? lines.length / 2 + 1
        : lines.length / 2;

    const firstLine = lines.slice(0, wordsLengthDivided).join(' ');
    const secondLine = lines.slice(wordsLengthDivided).join(' ');
    return (
      <div>
        <span className={css.spanHeroText}>{firstLine}</span>
        <br />
        <span className={css.spanHeroText}>{secondLine}</span>
      </div>
    );
  };

  const headerText = splitInTwoLines(
    intl.formatMessage({
      id: header,
    })
  );

  return (
    <div className={css.heroWrapper} id="heroWrapper">
      <div className={css.heroBackground} >
        <img
          className={css.heroBackgroundStatic}
          src={heroImage}
          alt="Background Hero Image"
          loading="eager"
          decoding="async"
          fetchpriority="high"
        />
      </div>
      <div className={css.textWrapper} ref={textWrapperRef}>
        <div className={css.textSectionWrapper}>
          <div className={css.headerWrapperRow}>
            <h1 style={{ color: 'white' }} className={css.header}>
              {headerText}
            </h1>
          </div>
          <div className={css.headerWrapperRow}>
            <p className={css.subHeader}>{heroText.subHeadline}</p>
          </div>
          {children}
          <div className={css.pressSection}>
            <PublicationSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HeroImage);
