import React, { useState } from 'react';
import css from './YourMomentSection.module.css';
import images from './images/index';

const YourMomentCard = ({ imageName, title, description, link }) => {
  const [, setStartPos] = useState({ x: 0, y: 0 });

  return (
    <div
      className={`${css.yourMomentCard} ${link ? css.clickable : ''}`}
      onMouseEnter={e => setStartPos({ x: e.clientX, y: e.clientY })}
      onMouseLeave={() => setStartPos({ x: 0, y: 0 })}
      style={{ cursor: link ? 'pointer' : 'default' }}
      role={link ? 'link' : 'presentation'}
    >
      <div className={css.imageContainer}>
        <img src={images[imageName]} alt={title} className={css.cardImage} loading="lazy"/>
        <h3 className={css.cardTitle}>{title}</h3>
        <div className={css.cardDescription}>
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default YourMomentCard;
