import React from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney, formatPriceFromUnitPurchase } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config/configDefault';
import { NamedLinkBlank } from '../../components';
import css from './ListingCardSmall.module.css';
import { extractDaysAndNights } from '../../util/listingPages.util';
import oneDay from '../../assets/experience-icons/1d.webp';
import twoDays from '../../assets/experience-icons/2d.webp';
import threeDays from '../../assets/experience-icons/3d.webp';
import fourDays from '../../assets/experience-icons/4d.webp';
import fiveDays from '../../assets/experience-icons/5d.webp';
import sixDays from '../../assets/experience-icons/6d.webp';
import eightDays from '../../assets/experience-icons/8d.webp';
import nineDays from '../../assets/experience-icons/9d.webp';
import tenDays from '../../assets/experience-icons/10d.webp';
import elevenDays from '../../assets/experience-icons/11d.webp';
import twelveDays from '../../assets/experience-icons/12d.webp';
import thirteenDays from '../../assets/experience-icons/13d.webp';
import OneWeek from '../../assets/experience-icons/1week.webp';
import TwoWeeks from '../../assets/experience-icons/2weeks.webp';
import ThreeWeeks from '../../assets/experience-icons/3weeks.webp';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const currencyCheck = (customCurrency, shareTribePrice) => {
  if (customCurrency != undefined) {
    if (customCurrency.includes('Euro')) {
      return shareTribePrice;
    } else {
      return shareTribePrice;
    }
  } else {
    return shareTribePrice;
  }
};
const scrollToTop = () => {
  if (typeof window !== 'undefined' && window?.document) {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};
export const ListingCardSmallComponent = props => {
  const { className, rootClassName, intl, listing, selected } = props;
  const classes = classNames(rootClassName || selected ? css.rootSecond : css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const projectRoomtypeRaw = title.split(' • ')[1];

  const ProjectRoomtype =
    projectRoomtypeRaw === 'singlebedroom' ? (
      <FormattedMessage id="roomtypes.singlebedroom" />
    ) : projectRoomtypeRaw === 'twobedroom' ? (
      <FormattedMessage id="roomtypes.twobedroom" />
    ) : projectRoomtypeRaw === 'doublebedroom' ? (
      <FormattedMessage id="roomtypes.doublebedroom" />
    ) : projectRoomtypeRaw === 'shared_bedroom' ? (
      <FormattedMessage id="roomtypes.shared_bedroom" />
    ) : projectRoomtypeRaw === 'multi_bedroom' ? (
      <FormattedMessage id="roomtypes.multi_bedroom" />
    ) : projectRoomtypeRaw === 'entire_accomodation' ? (
      <FormattedMessage id="roomtypes.entire_accomodation" />
    ) : projectRoomtypeRaw === 'tent' ? (
      <FormattedMessage id="roomtypes.tent" />
    ) : null;

  const pricePer =
    projectRoomtypeRaw === 'shared_bedroom' ? (
      <FormattedMessage id="ListingCard.pricePerBed" />
    ) : projectRoomtypeRaw === 'multi_bedroom' ? (
      <FormattedMessage id="ListingCard.pricePerRoom" />
    ) : projectRoomtypeRaw === 'singlebedroom' ? (
      <FormattedMessage id="ListingCard.pricePerRoom" />
    ) : projectRoomtypeRaw === 'twobedroom' ? (
      <FormattedMessage id="ListingCard.pricePerRoom" />
    ) : projectRoomtypeRaw === 'doublebedroom' ? (
      <FormattedMessage id="ListingCard.pricePerRoom" />
    ) : projectRoomtypeRaw === 'tent' ? (
      <FormattedMessage id="ListingCard.pricePerRoom" />
    ) : (
      ''
    );

  const ProjectRoomtypeIcon =
    projectRoomtypeRaw === 'singlebedroom' ? (
      <img
        src={require('../../assets/icons/roomtypes/onebedroom.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : projectRoomtypeRaw === 'twobedroom' ? (
      <img
        src={require('../../assets/icons/roomtypes/twobedroom.png')}
        className={css.iconStyle}
        alt="nachhaltiger bnb/sustainable bnb"
      ></img>
    ) : projectRoomtypeRaw === 'doublebedroom' ? (
      <img
        src={require('../../assets/icons/roomtypes/doublebedroom.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : projectRoomtypeRaw === 'shared_bedroom' ? (
      <img
        src={require('../../assets/icons/roomtypes/shared_bedroom.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : projectRoomtypeRaw === 'multi_bedroom' ? (
      <img
        src={require('../../assets/icons/roomtypes/multi_bedroom.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : projectRoomtypeRaw === 'entire_accomodation' ? (
      <img
        src={require('../../assets/icons/roomtypes/entire_accomodation.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : projectRoomtypeRaw === 'tent' ? (
      <img
        src={require('../../assets/icons/roomtypes/camping.png')}
        className={css.iconStyle}
        alt="nachhaltiger Tourismus/sustainable tourism"
      ></img>
    ) : null;

  const isExperienceType = listing.attributes?.publicData?.listingType == 'impact-experience';
  const getDaysAndNights = isExperienceType
    ? extractDaysAndNights(listing?.attributes?.publicData?.MDE)
    : '';
  const translatedMDELabel = isExperienceType ? (
    getDaysAndNights.days == 7 ? (
      <FormattedMessage id="searchPage.experienceLabel1Week" />
    ) : getDaysAndNights.days == 14 ? (
      <FormattedMessage id="searchPage.experienceLabel2Weeks" />
    ) : getDaysAndNights.days == 21 ? (
      <FormattedMessage id="searchPage.experienceLabel3Weeks" />
    ) : (
      <FormattedMessage
        id="listingPage.experiencePackageCardLabel"
        values={{ days: getDaysAndNights.days, nights: getDaysAndNights.nights }}
      />
    )
  ) : (
    ''
  );
  const experienceImages = [
    oneDay,
    twoDays,
    threeDays,
    fourDays,
    fiveDays,
    sixDays,
    OneWeek,
    eightDays,
    nineDays,
    tenDays,
    elevenDays,
    twelveDays,
    thirteenDays,
    TwoWeeks,
    ThreeWeeks,
  ];

  return (
    <NamedLinkBlank className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.info}
        onClick={() => {
          scrollToTop();
        }}
      >
        {isExperienceType ? (
          <div className={css.expImageContainer}>
            <img
              className={css.expImage}
              src={
                experienceImages[
                  getDaysAndNights.days > 13
                    ? Number(getDaysAndNights.days) / 7 + 11
                    : Number(getDaysAndNights.days) - 1
                ]
              }
            />
          </div>
        ) : (
          ProjectRoomtypeIcon
        )}
        <div className={css.mainInfo}>
          <div className={css.title}>
            <span className={css.title}>
              {isExperienceType ? translatedMDELabel : ProjectRoomtype}{' '}
            </span>
            <br />
          </div>
          <p className={css.price_wrapper}>
            {isExperienceType ? (
              <FormattedMessage
                id="listingPage.experiencePriceLabel"
                values={{ value: formatPriceFromUnitPurchase(intl, price.amount) }}
              />
            ) : (
              <>
                <span className={css.priceValue} title={priceTitle}>
                  {currencyCheck(
                    currentListing.attributes.publicData.customCurrency,
                    formattedPrice
                  )}
                </span>
                <span className={css.priceSubTxt}>
                  /<FormattedMessage id="Slider.night" /> {pricePer}
                </span>{' '}
              </>
            )}
          </p>
        </div>
      </div>
    </NamedLinkBlank>
  );
};

ListingCardSmallComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardSmallComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingCardSmallComponent);
