import React from 'react';
import { useParams } from 'react-router-dom';
import css from './ContactUsSection.module.css';
import whatsappIcon from '../QuickMenu/images/whatsappIcon.webp';
import alexImageWide from '../QuickMenu/images/01_Alex_wide.webp';
import alexImage from '../QuickMenu/images/01_Alex_orange.webp';
import img1 from '../../../../assets/sectionWhatsNew/ambassador1.webp';
import img2 from '../../../../assets/sectionWhatsNew/roundtrip.webp';
import { ExternalLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
// If you have localized text, you could use react-intl (FormattedMessage) here.

const ContactUsSection = () => {
  const forYou = typeof window !== 'undefined' ? (window?.location?.href).includes('forYou') : null;

  const { lang } = useParams();

  return (
    <div
      className={forYou ? css.containerReveresed : css.container}
      data-elbaction="visible:vis lp-contact-section"
    >
      {/* left image block */}
      <div className={forYou ? css.imageBlockReversed : css.imageBlock}>
        {/* desktop */}
        <div className={css.imgDesktWrapper}>
          <div className={css.img1}>
            <img src={img1} className={css.imgClass} alt="Background Image" />{' '}
          </div>
          <div className={css.img2}>
            <img src={img2} className={css.imgClass} alt="Background Image" />
          </div>
          <div className={css.img3}>
            <div className={css.img3content}>
              <img src={alexImageWide} className={css.imgClass} alt="Profile Image" loading="lazy" />
              <div className={css.img3textBlock}>
                <span className={css.img3Text}>Alex</span>
                <span className={css.img3Text}>socialbnb Team</span>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile */}
        <div className={css.imgMobileWrapper}>
          <h2 className={css.heading}>
            <FormattedMessage id="CustomLandingPage.contactUsSection.title" />
          </h2>
          <p className={css.description}>
            <FormattedMessage id="CustomLandingPage.contactUsSection.description" />
          </p>
          <div className={css.profileContainer}>
            <img src={alexImage} className={css.profileImage} alt="Profile Image" />

            <div className={css.profileImageText}>
              <span className={css.img3Text}>Alex</span>
              <span className={css.img3Text}>socialbnb Team</span>
            </div>
          </div>

          {/* WhatsApp row */}
          <div className={css.whatsappRow}>
            <ExternalLink
              href="https://wa.me/+4915730977660"
              data-elbaction="click: click lp-contact-whatsapp"
            >
              <img src={whatsappIcon} alt="WhatsApp Icon" className={css.whatsappIcon} />
            </ExternalLink>
            <span className={css.whatsappNumber}>+49 157 3097 76 60</span>
          </div>
          <ExternalLink
            href={
              lang === 'de'
                ? 'https://share.hsforms.com/1jVRJ8ItwS2yPCM1ElH4jbA4056k'
                : 'https://share.hsforms.com/1VEqKHhc9QPqRBM_09lXkKw4056k'
            }
          >
            <button className={css.contactButton} data-elbaction="click: click lp-contact-cta">
              <FormattedMessage id="CustomLandingPage.contactUsSection.CTA" />
            </button>
          </ExternalLink>
        </div>
      </div>

      {/* right text block */}
      <div className={css.textBlock}>
        <h2 className={css.heading}>
          <FormattedMessage id="CustomLandingPage.contactUsSection.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="CustomLandingPage.contactUsSection.description" />
        </p>
        <ExternalLink
          href={
            lang === 'de'
              ? 'https://share.hsforms.com/1jVRJ8ItwS2yPCM1ElH4jbA4056k'
              : 'https://share.hsforms.com/1VEqKHhc9QPqRBM_09lXkKw4056k'
          }
        >
          <button className={css.contactButton} data-elbaction="click: click lp-contact-cta">
            <FormattedMessage id="CustomLandingPage.contactUsSection.CTA" />
          </button>
        </ExternalLink>

        {/* WhatsApp row */}
        <div className={css.whatsappRow}>
          <ExternalLink
            href="https://wa.me/+4915730977660"
            data-elbaction="click: click lp-contact-whatsapp"
          >
            <img src={whatsappIcon} alt="WhatsApp Icon" className={css.whatsappIcon} />
          </ExternalLink>
          <span className={css.whatsappNumber}>+49 157 3097 76 60</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
