import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
//import { TweenMax } from 'gsap/all';

import { Page, LayoutSingleColumn } from '../../components';
import { ExternalLink } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterNew from '../../components/Footer/FooterNew';

import SectionImpact from '../../components/SectionImpact/SectionImpact';
import ImpactRepSection from '../../components/ImpactRepSection/ImpactRepSection';
import SectionValues from '../../components/SectionValues/SectionValues';
import SectionOurTeam from '../../components/SectionOurTeam/sectionOurTeam';

import MrThy from '../../assets/aboutPage/Mr_thy.png';
import MrThyLines from '../../assets/aboutPage/Mr_thy_lines.png';
import insta from '../../assets/icons/socialmedia/instagram-icon.png';
import fb from '../../assets/icons/socialmedia/facebook-icon.png';
import linkedIn from '../../assets/icons/socialmedia/linkedIn-icon.png';

import cloud1 from '../../assets/clouds/cloud1.webp';
import Houses from '../../assets/aboutPage/houses.png';
import Plus from '../../assets/aboutPage/plus.png';
import Traveler from '../../assets/aboutPage/traveler.png';

import article3 from '../../assets/partners/ECPAT.png';
import article4 from '../../assets/partners/Enactus.webp';
import article8 from '../../assets/partners/Gateway.png';
import article9 from '../../assets/partners/GIZ.png';
import article10 from '../../assets/partners/ImpactFactory.png';
import article11 from '../../assets/partners/far_Logo.jpg';
import article12 from '../../assets/partners/Bmwk-big-.webp';
import article13 from '../../assets/partners/Animondial.png';
import article14 from '../../assets/partners/Exist.png';
import article15 from '../../assets/partners/SEND.png';
import article16 from '../../assets/partners/planetera.png';
import article17 from '../../assets/partners/gruendungpreis.png';
import article18 from '../../assets/articles/article17.webp';
import articleTravelImpact from '../../assets/articles/article18.webp';
import innovatorsPitch from '../../assets/articles/article19.webp';
import ltandc from '../../assets/articles/lt_c_logo.webp';
import heroImage from '../../assets/aboutPage/heroImage.webp';
import LoaderImage1 from '../../assets/loader/world.png';
import LoaderImage2 from '../../assets/loader/airplane.png';
import Cookies from 'universal-cookie';
import css from './AboutPage.module.css';
import { useSelector } from 'react-redux';

const AboutPage = () => {
  const intl = useIntl();
  const [loadingState, setLoadingState] = useState(false);
  const [changeBlueTxt, setChangeBlueTxt] = useState(false);
  const [changeOrangeTxt, setChangeOrangeTxt] = useState(false);
  const currentLanguage = useSelector(state => (state?.ui?.language ? state?.ui?.language : 'en'));
  const cookies = new Cookies();
  var cookiesMaybe = cookies?.get('language') ? cookies?.get('language') : 'en';
  useEffect(() => {
    setTimeout(() => {
      setLoadingState(true);
    }, 1000);
  }, []);

  const topbarRef = React.useRef(null);

  const loader = !loadingState ? (
    <div className={css.loader}>
      <img
        src={LoaderImage2}
        alt="loaderImage"
        className={`${css.loaderImage2} ${css.rotateAnimation}`}
        width="100px"
      ></img>

      <img
        src={LoaderImage1}
        alt="loaderImage1"
        className={`${css.loaderImage1} ${css.moveAnimation}`}
        width="100px"
      ></img>
    </div>
  ) : null;

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.document) {
      document?.body?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const changeBlueText = () => {
    setChangeBlueTxt(true);
    setChangeOrangeTxt(false);
  };

  const changeOrangeText = () => {
    setChangeBlueTxt(false);
    setChangeOrangeTxt(true);
  };

  const changetxtToOriginal = () => {
    setChangeBlueTxt(false);
    setChangeOrangeTxt(false);
  };
  const whyWrapperContent = loadingState === true && (
    <div
      className={css.section}
      data-elb="section"
      data-elb-section="name:whyText;position:3"
      data-elbaction="visible"
    >
      <div className={css.whyWrapper}>
        <div className={css.whyTextWrapper}>
          <h2 id="anima" className={css.whyTextHeader}>
            <FormattedMessage id="about.whyTextblock1Header" />
          </h2>

          <p className={css.text}>
            <FormattedMessage id="about.whyTextblock1Text" />
          </p>
        </div>
        <div className={css.whyTextWrapper}>
          <div className={css.whyTextHeaderWrapper}>
            <h2 id="anima" className={`${css.whyTextHeader} ${css.whyTextBlock2Header}`}>
              <FormattedMessage id="about.whyTextblock2Header" />
            </h2>
          </div>
          <p className={css.text}>
            <FormattedMessage id="about.whyTextblock2Text" />
          </p>
        </div>
      </div>
      <div className={css.imagesWrapper}>
        <div className={`${css.imagesItemsOuter} ${css.houses}`}>
          <img src={Houses} alt="houses"></img>
        </div>
        <div className={css.imagesItemsInner}>
          <img src={Plus} alt="additional"></img>
        </div>
        <div className={`${css.imagesItemsOuter} ${css.traveller}`}>
          <img src={Traveler} alt="traveller"></img>
        </div>
      </div>
      <hr className={css.divider}></hr>
      <div
        className={css.section_graph}
        data-elb="section"
        data-elb-section="name:whereDoesMyMoneyGo;position:4"
        data-elbaction="visible"
      >
        <div className={css.graphText}>
          {(() => {
            if (changeBlueTxt) {
              return (
                <>
                  <h3>
                    <FormattedMessage id="about.lightGrayTxt" />
                  </h3>
                  <h2 className={css.blueTitle}>
                    <FormattedMessage id="about.graphBlueTitle" />{' '}
                  </h2>
                  <p>
                    <FormattedMessage id="about.graphBlueTxt" />
                  </p>
                </>
              );
            } else if (changeOrangeTxt) {
              return (
                <>
                  <h3>
                    <FormattedMessage id="about.lightGrayTxt" />
                  </h3>
                  <h2 className={css.orangeTitle}>
                    <FormattedMessage id="about.graphOrangeTitle" />
                  </h2>
                  <p>
                    <FormattedMessage id="about.graphOrangeTxt" />
                  </p>
                </>
              );
            }
            return (
              <>
                <h3>
                  <FormattedMessage id="about.lightGrayTxt" />
                </h3>
                <h2 className={css.blueTitle}>
                  <FormattedMessage id="about.graphOriginalTitle" />
                </h2>
                <p>
                  <FormattedMessage id="about.graphOriginalTxt" />
                </p>
              </>
            );
          })()}
        </div>
        <div className={css.middleLine}></div>
        <div className={css.graphSection}>
          <svg
            className={css.Layer_1}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 778.71 662.63"
            onMouseLeave={changetxtToOriginal}
          >
            <title>Svg Graph</title>
            <g
              className={css.rightBGraph}
              /*onMouseOver={e => {
                e.preventDefault();
                changeBlueText;
              }}
              onMouseEnter={changeBlueText} */
            >
              <path
                className={css.rightline}
                d="M821.36,205.87V478.63h-5.94c-32.31,0-64.64.07-97-.08h-8.29V465.75h8.29l82,.16c7.39,0,7.39,0,7.39-7.37,0-115.28,0-127.5,0-242.83,0-11.75-2-10.2,10.81-10.28A18,18,0,0,1,821.36,205.87Z"
                transform="translate(-42.64 -100.68)"
                fill="#606161"
              />
              <path
                className={css.rightgraph}
                d="M495.6,536.93,595.71,719.28c59-35.52,103.07-83.68,132.19-145.39.52-1.11,9.3-21.41,9.3-21.41C751.56,514,759.36,475,759.2,434l-208.64,1.67C549,478.57,530.81,512.15,495.6,536.93Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeBlueText;
                }}
                onMouseEnter={changeBlueText}
              />
              <text
                transform="translate(740 97.35)"
                fontSize="48"
                fill="#231f20"
                fontFamily="MyriadPro-Bold, Myriad Pro"
                className={css.rightGtxt}
                fontWeight="700"
              >
                15%
              </text>
            </g>
            <g
              className={css.leftOGraph}
              /*  onMouseOver={e => {
                e.preventDefault();
                changeOrangeText;
              }} */
              /*onMouseOver={e => {
                e.preventDefault();
              changeOrangeText;
              }}
              onMouseEnter={changeOrangeText} */
            >
              <path
                className={css.leftline}
                d="M42.64,738.68V465.92h5.94c32.31,0,64.64-.07,97,.08h8.29v12.79h-8.29l-82-.16c-7.39,0-7.39,0-7.39,7.37,0,115.28,0,127.5,0,242.83,0,11.75,2,10.2-10.81,10.28A18,18,0,0,1,42.64,738.68Z"
                transform="translate(-42.64 -100.68)"
                fill="#606161"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <path
                className={css.leftgraph}
                d="M550.18,433.92l209.54-1.59C755.73,308.46,702.07,214,598,147L489.83,330.1C528.26,354.09,548.26,388.67,550.18,433.92Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <path
                className={css.leftgraph}
                d="M265.37,711.49l0,0L364.47,547a7.44,7.44,0,0,0-2.16-10h-.05c-34.92-24-53.83-56.83-54.91-99.4-.13-5-2-6-6.47-6q-95.71-.77-191.42-1.82c-11.44-.12-11.41-.15-11.17,11.34A325.7,325.7,0,0,0,125,563.17c.44,1,10.19,21.29,10.4,21.7,27.2,51,64.16,92.53,112.24,124.35,2.46,1.63,5,3.18,7.53,4.73A7.45,7.45,0,0,0,265.37,711.49Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <path
                className={css.leftgraph}
                d="M487.87,329.19,596.62,145.92q-90.15-51.95-193-44.24A322.34,322.34,0,0,0,266.7,143.37L371.07,328.08C410.51,308.64,449.22,309.24,487.87,329.19Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <path
                className={css.leftgraph}
                d="M103.45,427.62q99,.73,197.89,1.89c4.5,0,5.82-1.27,6.29-5.77,3.5-33.73,18.34-61.52,44.69-83,5.14-4.18,10.77-7.76,16.65-12L264.88,144.54c-1.81,1-3.28,1.75-4.69,2.58A310.94,310.94,0,0,0,205,188.22c-67.15,63.33-103.12,141-106.74,233.39C98.07,425.63,98.63,427.58,103.45,427.62Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <path
                className={css.leftgraph}
                d="M370,542,263.45,718.83c111.87,65.7,246,52.48,330.4.31l-100.78-181C452.37,561.61,411.48,562.54,370,542Z"
                transform="translate(-42.64 -100.68)"
                fill="#010101"
                onMouseOver={e => {
                  e.preventDefault();
                  changeOrangeText;
                }}
                onMouseEnter={changeOrangeText}
              />
              <text
                transform="translate(-26 679.35)"
                fontSize="48"
                fill="#231f20"
                fontFamily="MyriadPro-Bold, Myriad Pro"
                fontWeight="700"
                className={css.leftGtxt}
              >
                85%
              </text>
            </g>
          </svg>
        </div>
      </div>
      <hr className={css.divider}></hr>

      <SectionOurTeam />

      <hr className={css.divider}></hr>
      <SectionValues />
    </div>
  );
  const content = (
    <div style={{ overflow: 'hidden' }}>
      <div id="topbar" className={css.topbar} style={{ position: 'fixed', width: '100vw' }}>
        <TopbarContainer />
      </div>

      <div className={css.LeftFooterContent}>
        <ExternalLink
          href="https://www.instagram.com/socialbnb"
          target="_blank"
          className={css.socialmedia}
        >
          <img src={insta} className={css.socialmedia} alt="instagram"></img>
        </ExternalLink>

        <ExternalLink
          href="https://www.facebook.com/Socialbnb"
          target="_blank"
          className={css.socialmedia}
        >
          <img src={fb} className={css.socialmedia} alt="facebook"></img>
        </ExternalLink>

        <ExternalLink
          href="https://www.linkedin.com/company/socialbnb/"
          target="_blank"
          className={css.socialmedia}
        >
          <img src={linkedIn} className={css.socialmedia} alt="facebook"></img>
        </ExternalLink>
      </div>
      <div
        className={css.section}
        data-elb="section"
        data-elb-section="name:hero;position:1"
        data-elbaction="visible"
      >
        <div
          id="heroWrapper"
          className={css.heroWrapper}
          style={{ margnTop: '0', height: '100vh', overflow: 'hidden' }}
        >
          <div className={css.HeaderWrapper}>
            <h1
              id="anima"
              className={
                loadingState === false ? css.Header1 : `${css.Header1} ${css.Header1Visible}`
              }
            >
              <FormattedMessage id="AboutPage.h1.part2" />
            </h1>

            <div
              id="anima3"
              className={
                loadingState === false
                  ? css.SubHeader1
                  : `${css.SubHeader1} ${css.SubHeader1Visible}`
              }
            >
              <h2>
                <FormattedMessage id="AboutPage.h2" />
              </h2>
            </div>
          </div>

          <div
            className={
              loadingState === false
                ? css.cloudsouter
                : `${css.cloudsouter} ${css.cloudsouterVisible}`
            }
          >
            <img
              className={loadingState === false ? css.clouds : `${css.clouds} ${css.cloudsVisible}`}
              alt="clouds"
              src={cloud1}
            ></img>
          </div>
          <img
            className={
              loadingState === false ? css.heroImage : `${css.heroImage} ${css.heroImageVisible}`
            }
            src={heroImage}
            alt="heroImage"
            width="100%"
            onLoad={() => {}}
            onError={e => {
              console.error('Error loading image:', e);
            }}
          />
        </div>
      </div>

      <div className={css.section}>
        <div className={css.storyWrapper}>
          <div className={css.storyPhotoWrapper}>
            <img src={MrThyLines} alt="mrThylines" className={css.storyPhoto1}></img>
            <img
              //   ref={thyEle}
              src={MrThy}
              className={css.storyPhoto_hide}
              alt=""
            ></img>
          </div>
          <div className={css.storyTextWrapper}>
            <div
              className={css.whyTextWrapper}
              data-elb="section"
              data-elb-section="name:OurStory;position:2"
              data-elbaction="visible"
            >
              <h2 id="anima" className={css.whyTextHeader}>
                <FormattedMessage id="about.storyHeadline" />
              </h2>

              <div className={css.text}>
                <p>
                  <FormattedMessage id="about.storyTextBlock1" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className={css.divider}></hr>
      <div> {whyWrapperContent} </div>
      <hr className={css.divider}></hr>
      <SectionImpact />
      <ImpactRepSection params={{ lang: currentLanguage }} />

      <div
        className={css.section4}
        data-elb="section"
        data-elb-section="name:partnersSection;position:9"
        data-elbaction="visible"
      >
        <div className={css.partnersWrapper}>
          <h2 id="partner" className={css.partnersHeader}>
            <FormattedMessage id="about.partnersHeadline" />
          </h2>

          <div className={css.articles}>
            <div className={css.articlesItems}>
              <ExternalLink href="https://ecpat.de/" target="_blank" style={{ height: '100%' }}>
                <img src={article3} alt="article3"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.enactus.de/uni-koeln/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article4} alt="article4"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.gateway.uni-koeln.de/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article8} alt="article8"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.giz.de/de/html/index.html"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article9} alt="article9"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://impact-factory.de/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article10} alt="article10"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://forumandersreisen.de/startseite/aktuelles/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article11} alt="article11"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.bmwk.de/Navigation/DE/Home/home.html"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article12} alt="article12"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.unwto.org/unwto-awake-tourism-challenge"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article18} alt="article18"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://animondial.com/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article13} alt="article13"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.exist.de/EXIST/Navigation/DE/Home/home.html"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article14} alt="article14"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://planeterra.org/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article16} alt="article16"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.de.digital/DIGITAL/Navigation/DE/Home/home.html"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article17} alt="article17"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.send-ev.de/"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={article15} alt="article15"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.bitkom.org/Themen/Startups/Innovators-Pitch"
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={innovatorsPitch} alt="innovatorsPitch"></img>
              </ExternalLink>
            </div>

            <div className={css.articlesItems}>
              <ExternalLink
                href="https://www.ltandc.org/ "
                target="_blank"
                style={{ height: '100%' }}
              >
                <img src={ltandc} alt="ltandc"></img>
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
      <div className={`${css.section} ${css.section5}`}></div>
    </div>
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'AboutPage.title' })}
      description={intl.formatMessage({ id: 'AboutPage.desc' })}
      keywords={intl.formatMessage({ id: 'AboutPage.keywords' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: intl.formatMessage({ id: 'AboutPage.title' }),
        url: `https://socialbnb.org/${intl?.locale || 'en'}/about`,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={
          <div
            id="topbar"
            ref={topbarRef}
            // className={
            //   scrollPosition > 420 ||
            //   (typeof window !== 'undefined' && window.innerWidth < 1200 && scrollPosition > 240)
            //     ? css.topbarScrolled
            //     : css.topbar
            // }
            style={{ position: 'fixed', width: '100vw', top: 0 }}
            data-elbglobals="pagetype:AboutUs"
            data-elbcontext="booking:aboutUs"
          >
            <TopbarContainer />
          </div>
        }
        footer={<FooterNew />}
      >
        <div className={css.Wrapper}>
          {loader} {content}
        </div>
        <>
          {changeOrangeTxt}
          {changeBlueTxt}
        </>
      </LayoutSingleColumn>
    </Page>
  );
};

export default AboutPage;
