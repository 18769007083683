import { fetchPageAssets } from '../../ducks/hostedAssets.duck';

export const loadData = (params, search) => (dispatch, getState) => {
  const pageId = params.pageId;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;

  const { language } = getState().ui;

  loadData.lastLanguage = language;

  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent, pageId));
};

export const handleLanguageChange = () => (dispatch, getState) => {
  const { language } = getState().ui;
  const params = getState().router.params;

  if (language !== loadData.lastLanguage && params.pageId) {
    loadData.lastLanguage = language;
    dispatch(loadData(params));
  }
};
