import React, { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './YourMomentSection.module.css';
import YourMomentCard from './YourMomentCard';
import { Link, useParams } from 'react-router-dom';
import './swiper-bundle.css';
import './swiper-bundle.min.css';
import Swiper from 'swiper';
import SwiperCore, { Autoplay } from 'swiper/core';

SwiperCore.use([Autoplay]);

const profileImages = {
  profileImage1: () => import('./images/profile-pic-1.webp'),
  profileImage2: () => import('./images/profile-pic-2.webp'),
  profileImage3: () => import('./images/profile-pic-3.webp'),
  profileImage4: () => import('./images/Pic-5.webp'),
};

const moments = [
  {
    image: 'Pic-1',
    title: <FormattedMessage id="yourmomentssection.card1.title" />,
    description: <FormattedMessage id="yourmomentssection.card1.description" />,
    link:
      'https://www.socialbnb.org/de/l/kapawi-4-days-3-nights/66c59c1a-426b-49f9-bd4a-5fc994ef33ca',
  },
  {
    image: 'Pic-2',
    title: <FormattedMessage id="yourmomentssection.card2.title" />,
    description: <FormattedMessage id="yourmomentssection.card2.description" />,
    link:
      'https://www.socialbnb.org/de/l/ischia-dolphin-project-7-days-6-nights/66c5a193-f9ff-44f7-a963-8ac28ae831b2',
  },
  {
    image: 'Pic-3',
    title: <FormattedMessage id="yourmomentssection.card3.title" />,
    description: <FormattedMessage id="yourmomentssection.card3.description" />,
    link:
      'https://www.socialbnb.org/de/l/living-seas-asia-2-days-1-night/67265415-f122-4645-abb1-11304128cd00',
  },
  {
    image: 'Pic-4',
    title: <FormattedMessage id="yourmomentssection.card4.title" />,
    description: <FormattedMessage id="yourmomentssection.card4.description" />,
    link:
      'https://www.socialbnb.org/de/l/astungkara-way-2-days-1-night/67619081-3913-45f5-af6e-fa61b2f8dc1f',
  },
  {
    image: 'Pic-5',
    title: <FormattedMessage id="yourmomentssection.card5.title" />,
    description: <FormattedMessage id="yourmomentssection.card5.description" />,
    link:
      'https://www.socialbnb.org/de/l/orto-dell-idro-doublebedroom/6233ba15-0b00-42a1-9992-d46b03f5196d',
  },
  {
    image: 'Pic-6',
    title: <FormattedMessage id="yourmomentssection.card6.title" />,
    description: <FormattedMessage id="yourmomentssection.card6.description" />,
    link:
      'https://www.socialbnb.org/de/l/ceylon-food-trails-4-days-3-nights/671790c3-906a-4553-b881-5e04b4ba53a7',
  },
  {
    image: 'Pic-7',
    title: <FormattedMessage id="yourmomentssection.card7.title" />,
    description: <FormattedMessage id="yourmomentssection.card7.description" />,
    link:
      'https://www.socialbnb.org/de/l/inti-sisa-responsible-travel-sa-doublebedroom/65775aac-8179-47c7-b9d2-c5ecb49474c8',
  },
  {
    image: 'Pic-8',
    title: <FormattedMessage id="yourmomentssection.card8.title" />,
    description: <FormattedMessage id="yourmomentssection.card8.description" />,
    link:
      'https://www.socialbnb.org/de/l/ecoturismo-comunitario-yunguilla-twobedroom/63a8ae12-1101-4b75-8ed4-338d14577c7b',
  },
  {
    image: 'Pic-9',
    title: <FormattedMessage id="yourmomentssection.card9.title" />,
    description: <FormattedMessage id="yourmomentssection.card9.description" />,
    link:
      'https://www.socialbnb.org/de/l/turismo-comunitario-salasaka-twobedroom/6065caa6-39a5-438b-a862-7fd72e8943ee',
  },
  {
    image: 'Pic-10',
    title: <FormattedMessage id="yourmomentssection.card10.title" />,
    description: <FormattedMessage id="yourmomentssection.card10.description" />,
    link:
      'https://www.socialbnb.org/de/l/dos-aguas-double-with-balcony-doublebedroom/65a6fd8a-68c5-41bb-bc71-bccdd0716988',
  },
  {
    image: 'Pic-11',
    title: <FormattedMessage id="yourmomentssection.card11.title" />,
    description: <FormattedMessage id="yourmomentssection.card11.description" />,
    link:
      'https://www.socialbnb.org/de/l/dos-aguas-double-with-balcony-doublebedroom/65a6fd8a-68c5-41bb-bc71-bccdd0716988',
  },
  {
    image: 'Pic-12',
    title: <FormattedMessage id="yourmomentssection.card12.title" />,
    description: <FormattedMessage id="yourmomentssection.card12.description" />,
    link:
      'https://www.socialbnb.org/de/l/orto-dell-idro-doublebedroom/6233ba15-0b00-42a1-9992-d46b03f5196d',
  },
  {
    image: 'Pic-13',
    title: <FormattedMessage id="yourmomentssection.card13.title" />,
    description: <FormattedMessage id="yourmomentssection.card13.description" />,
    link:
      'https://www.socialbnb.org/de/l/myfarm-africa-start-up-doublebedroom/634d4aea-de92-465b-a367-a4b966e9b3fd',
  },
  {
    image: 'Pic-14',
    title: <FormattedMessage id="yourmomentssection.card14.title" />,
    description: <FormattedMessage id="yourmomentssection.card14.description" />,
    link:
      'https://www.socialbnb.org/de/l/magic-alebrijes-adopt-a-pinata-doublebedroom/647cdac3-5088-427b-a919-93018b00807b',
  },
  {
    image: 'Pic-15',
    title: <FormattedMessage id="yourmomentssection.card15.title" />,
    description: <FormattedMessage id="yourmomentssection.card15.description" />,
    link:
      'https://www.socialbnb.org/de/l/esperanza-zentrum-fur-tiergestutzte-padagogik-entire-accomodation/628e8233-d722-4d54-ada5-2a660f8e9515',
  },
  {
    image: 'Pic-16',
    title: <FormattedMessage id="yourmomentssection.card16.title" />,
    description: <FormattedMessage id="yourmomentssection.card16.description" />,
    link: 'https://www.socialbnb.org/de/p/ecoexplora-ecuador',
  },
  {
    image: 'Pic-17',
    title: <FormattedMessage id="yourmomentssection.card17.title" />,
    description: <FormattedMessage id="yourmomentssection.card17.description" />,
    link:
      'https://www.socialbnb.org/de/l/project-wings-twobedroom/62deafa0-5661-4bb7-a058-ea7c1166cf3b',
  },
  {
    image: 'Pic-18',
    title: <FormattedMessage id="yourmomentssection.card18.title" />,
    description: <FormattedMessage id="yourmomentssection.card18.description" />,
    link:
      'https://www.socialbnb.org/de/l/reserva-biologica-caoba-doublebedroom/62337420-e9af-43f0-98d3-8efd7edbe433',
  },
  {
    image: 'Pic-19',
    title: <FormattedMessage id="yourmomentssection.card19.title" />,
    description: <FormattedMessage id="yourmomentssection.card19.description" />,
    link:
      'https://www.socialbnb.org/de/l/responsible-travel-nuratau-community-tourism-sharedbedroom/6303b10c-9860-4a21-884b-de9e0ebcf8c6',
  },
  {
    image: 'Pic-20',
    title: <FormattedMessage id="yourmomentssection.card20.title" />,
    description: <FormattedMessage id="yourmomentssection.card20.description" />,
    link:
      'https://www.socialbnb.org/de/l/casa-in-mezzo-zonca-entire-accomodation/67054f87-f314-4ca0-ac1f-d7e8449ab102',
  },
].map((moment, index) => ({
  ...moment,
  key: `${moment.image}-${index}`,
}));

const ProfileImages = memo(({ images }) => (
  <div className={css.profileImages}>
    {images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt={`Profile ${index + 1}`}
        className={css.profileImage}
        loading="lazy"
        width="40"
        height="40"
      />
    ))}
  </div>
));

const MemoizedCard = memo(({ moment }) => (
  <div
    className="swiper-slide"
    onClick={() => window.open(moment.link, '_blank', 'noopener,noreferrer')}
  >
    <YourMomentCard
      imageName={moment.image}
      title={moment.title}
      description={moment.description}
      link={moment.link}
    />
  </div>
));

const YourMomentSection = () => {
  const { lang } = useParams();
  const [headerProfileImages, setHeaderProfileImages] = useState([]);
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const loadProfileImages = async () => {
      try {
        const images = await Promise.all(
          Object.values(profileImages).map(importFn => importFn().then(module => module.default))
        );
        setHeaderProfileImages(images);
      } catch (error) {
        console.error('Error loading profile images:', error);
      }
    };

    loadProfileImages();
  }, []);
  useEffect(() => {
    if (!containerRef.current) return;

    const swiperOptions = {
      autoplay: {
        delay: 0,
      },
      loop: true,
      speed: 8000,
      spaceBetween: 16,
      slidesPerView: 'auto',
      freeMode: true,
      breakpoints: {
        320: {
          slidesPerView: 2.2,
          spaceBetween: 6,
        },
        520: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 12,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 12,
        },
        1280: {
          slidesPerView: 4.5,
          spaceBetween: 12,
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 12,
        },
        1600: {
          slidesPerView: 5.6,
          spaceBetween: 16,
        },
        1920: {
          slidesPerView: 6.6,
          spaceBetween: 17,
        },
        2560: {
          slidesPerView: 7,
          spaceBetween: 16,
        },
      },
    };

    swiperRef.current = new Swiper(containerRef.current, swiperOptions);

    const container = containerRef.current;
    const swiper = swiperRef.current;

    const handleMouseEnter = () => swiper.autoplay?.stop();
    const handleMouseLeave = () => swiper.autoplay?.start();

    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('mouseleave', handleMouseLeave);

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            swiper.update();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(container);

    return () => {
      observer.disconnect();
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('mouseleave', handleMouseLeave);
      swiper.destroy(true, true);
    };
  }, []);

  return (
    <div className={css.yourMomentSection}>
      <div className={css.sectionHeader}>
        <h2>
          <FormattedMessage id="yourmomentssection.title" />
        </h2>
        <div className={css.subHeader}>
          <ProfileImages images={headerProfileImages} />
          <div>
            <div className={css.headerSubtext}>
              <FormattedMessage id="yourmomentssection.description" />
            </div>
          </div>
        </div>
      </div>

      <div ref={containerRef} className="swiper-container">
        <div className="swiper-wrapper">
          {moments.map(moment => (
            <MemoizedCard key={moment.key} moment={moment} />
          ))}
        </div>
      </div>

      <div className={css.buttonDiv}>
        <Link
          to={`/${lang}/s`}
          target="_blank"
          rel="noopener noreferrer"
          className={css.buttonDivLink}
        >
          <button type="button" className={css.exploreButton}>
            <FormattedMessage id="landing.explore.now" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default memo(YourMomentSection);
