import React, { useEffect } from 'react';

export const useTouchScroll = (sliderRef, isMobileDevice) => {
  useEffect(() => {
    if (!isMobileDevice || !sliderRef.current) return;

    const slider = sliderRef.current;
    const scrollFactor = 30;

    const handleTouchStart = event => {
      const startX = event.touches[0].clientX;
      event.target.dataset.startX = startX;
    };

    const handleTouchMove = event => {
      const startX = parseFloat(event.target.dataset.startX);
      const touchX = event.touches[0].clientX;
      const deltaX = startX - touchX;
      const effectiveDeltaX = deltaX * scrollFactor;

      slider.scrollLeft += effectiveDeltaX;
      event.target.dataset.startX = touchX;
      event.preventDefault();
    };

    slider.addEventListener('touchstart', handleTouchStart);
    slider.addEventListener('touchmove', handleTouchMove);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart);
      slider.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMobileDevice]);
};
