/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',

  //ENQUIRE_1: 'transition/enquire-1',
  //ENQUIRE_2: 'transition/enquire-2',

  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',

  PROVIDER_DECLINE_BEFORE: 'transition/decline-provider-before',
  PROVIDER_DECLINE_AFTER: 'transition/decline-provider-after',

  CUSTOMER_DECLINE_BEFORE: 'transition/decline-customer-before',
  CUSTOMER_DECLINE_AFTER: 'transition/decline-customer-after',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_REMINDER_1: 'transition/review-reminder-1',
  REVIEW_REMINDER_2: 'transition/review-reminder-2',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  PAYPAL_PAYMENT: 'transition/paypal-payment',

  // imapct-experience
  STRIPE_PAYMENT: 'transition/stripe-payment',
  EXPIRE_EXP: 'transition/expire-exp',
  OPERATOR_DECLINE_EXP: 'transition/operator-decline-exp',
  CUSTOMER_DECLINE_BEFORE_EXP: 'transition/decline-customer-before-exp',
  PROVIDER_DECLINE_BEFORE_EXP: 'transition/decline-provider-before-exp',
  ACCEPT_EXP: 'transition/accept-exp',
  OPERATOR_ACCEPT_EXP: 'transition/operator-accept-exp',
  PROVIDER_DECLINE_AFTER_EXP: 'transition/decline-provider-after-exp',
  CUSTOMER_DECLINE_AFTER_EXP: 'transition/decline-customer-after-exp',
  CANCEL_EXP: 'transition/cancel-exp',
  PAYMENT_POSSIBLE_EXP: 'transition/payment-possible-exp',
  PAYMENT_EXPIRED_EXP: 'transition/payment-expired-exp',
  PAY_EXP: 'transition/pay-exp',
  DECLINE_PROVIDER_AFTER_EXP_2: 'transition/decline-provider-after-exp-2',
  DECLINE_CUSTOMER_AFTER_EXP_2: 'transition/decline-customer-after-exp-2',
  CANCEL_EXP_2: 'transition/cancel-exp-2',
  COMPLETE_EXP: 'transition/complete-exp',
  OPERATOR_COMPLETE_EXP: 'transition/operator-complete-exp',
  REVIEW_REMINDER_EXP_1: 'transition/review-reminder-exp-1',
  REVIEW_REMINDER_EXP_2: 'transition/review-reminder-exp-2',
  EXPIRE_REVIEW_PERIOD_EXP: 'transition/expire-review-period-exp',
  REVIEW_1_BY_CUSTOMER_EXP: 'transition/review-1-by-customer-exp',
  REVIEW_1_BY_PROVIDER_EXP: 'transition/review-1-by-provider-exp',
  REVIEW_2_BY_CUSTOMER_EXP: 'transition/review-2-by-customer-exp',
  REVIEW_2_BY_PROVIDER_EXP: 'transition/review-2-by-provider-exp',
  EXPIRE_CUSTOMER_REVIEW_PERIOD_EXP: 'transition/expire-customer-review-period-exp',
  EXPIRE_PROVIDER_REVIEW_PERIOD_EXP: 'transition/expire-provider-review-period-exp',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',

  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  DECLINED_AFTER_CUSTOMER: 'declined-after-customer',
  DECLINED_CUSTOMER: 'declined-customer',
  DECLINED_AFTER_PROVIDER: 'declined-after-provider',
  DECLINED_PROVIDER: 'declined-provider',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  DELIVERED: 'delivered',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',

  PREAUTHORIZED_EXP: 'preauthorized-exp',
  EXPIRED_EXP: 'expired-exp',
  DECLINED_EXP: 'declined-exp',
  DECLINED_CUSTOMER_EXP: 'declined-customer-exp',
  DECLINED_PROVIDER_EXP: 'declined-provider-exp',
  ACCEPTED_EXP: 'accepted-exp',
  DECLINED_AFTER_PROVIDER_EXP: 'declined-after-provider-exp',
  DECLINED_AFTER_CUSTOMER_EXP: 'declined-after-customer-exp',
  CANCELLED_EXP: 'cancelled-exp',
  PAYABLE_EXP: 'payable-exp',
  BOOKING_CANCELLED_EXP: 'booking-cancelled-exp',
  PAYED_EXP: 'payed-exp',
  DECLINED_AFTER_CUSTOMER_EXP_2: 'declined-after-customer-exp-2',
  DECLINED_AFTER_PROVIDER_EXP_2: 'declined-after-provider-exp-2',
  CANCELLED_EXP_2: 'cancelled-exp-2',
  DELIVERED_EXP: 'delivered-exp',
  REVIEWED_BY_PROVIDER_EXP: 'reviewed-by-provider-exp',
  REVIEWED_BY_CUSTOMER_EXP: 'reviewed-by-customer-exp',
  REVIEWED_EXP: 'reviewed-exp',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.PAYPAL_PAYMENT]: states.PREAUTHORIZED,
        [transitions.STRIPE_PAYMENT]: states.PREAUTHORIZED_EXP,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.DECLINE]: states.DECLINED,
        [transitions.CUSTOMER_DECLINE_BEFORE]: states.DECLINED_CUSTOMER,
        [transitions.PROVIDER_DECLINE_BEFORE]: states.DECLINED_PROVIDER,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.OPERATOR_ACCEPT]: states.ACCEPTED,
      },
    },

    [states.DECLINED]: {},
    [states.CUSTOMER_DECLINE_AFTER]: {},
    [states.CUSTOMER_DECLINE_BEFORE]: {},
    [states.DECLINED_AFTER_PROVIDER]: {},
    [states.PROVIDER_DECLINE_BEFORE]: {},
    [states.EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL]: states.CANCELED,
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
        [transitions.PROVIDER_DECLINE_AFTER]: states.DECLINED_AFTER_PROVIDER,
        [transitions.CUSTOMER_DECLINE_AFTER]: states.DECLINED_AFTER_CUSTOMER,
      },
    },

    [states.CANCELED]: {},
    [states.DELIVERED]: {
      on: {
        [transitions.REVIEW_REMINDER_1]: states.DELIVERED,
        [transitions.REVIEW_REMINDER_2]: states.DELIVERED,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },

    // impact-experience
    [states.PREAUTHORIZED_EXP]: {
      on: {
        [transitions.DECLINE_EXP]: states.DECLINED_EXP,
        [transitions.EXPIRE_EXP]: states.EXPIRED_EXP,
        [transitions.OPERATOR_DECLINE_EXP]: states.DECLINED_EXP,
        [transitions.CUSTOMER_DECLINE_BEFORE_EXP]: states.DECLINED_CUSTOMER_EXP,
        [transitions.PROVIDER_DECLINE_BEFORE_EXP]: states.DECLINED_PROVIDER_EXP,
        [transitions.ACCEPT_EXP]: states.ACCEPTED_EXP,
        [transitions.OPERATOR_ACCEPT_EXP]: states.ACCEPTED_EXP,
      },
    },

    [states.EXPIRED_EXP]: {},
    [states.DECLINED_EXP]: {},
    [states.DECLINED_CUSTOMER_EXP]: {},
    [states.DECLINED_PROVIDER_EXP]: {},

    [states.ACCEPTED_EXP]: {
      on: {
        [transitions.PROVIDER_DECLINE_AFTER_EXP]: states.DECLINED_AFTER_PROVIDER_EXP,
        [transitions.CUSTOMER_DECLINE_AFTER_EXP]: states.DECLINED_AFTER_CUSTOMER_EXP,
        [transitions.CANCEL_EXP]: states.CANCELLED_EXP,
        [transitions.PAYMENT_POSSIBLE_EXP]: states.PAYABLE_EXP,
      },
    },

    [states.DECLINED_AFTER_CUSTOMER_EXP]: {},
    [states.DECLINED_AFTER_PROVIDER_EXP]: {},
    [states.CANCELLED_EXP]: {},

    [states.PAYABLE_EXP]: {
      on: {
        [transitions.PAYMENT_EXPIRED_EXP]: states.BOOKING_CANCELLED_EXP,
        [transitions.PAYMENT_REMINDER_EXP_1]: states.PAYABLE_EXP,
        [transitions.PAYMENT_REMINDER_EXP_2]: states.PAYABLE_EXP,
        [transitions.PAYMENT_REMINDER_EXP_3]: states.PAYABLE_EXP,
        [transitions.PAY_EXP]: states.PAYED_EXP,
      },
    },

    [states.BOOKING_CANCELLED_EXP]: {},

    [states.PAYED_EXP]: {
      on: {
        [transitions.DECLINE_PROVIDER_AFTER_EXP_2]: states.DECLINED_AFTER_PROVIDER_EXP_2,
        [transitions.DECLINE_CUSTOMER_AFTER_EXP_2]: states.DECLINED_AFTER_CUSTOMER_EXP_2,
        [transitions.CANCEL_EXP_2]: states.CANCELLED_EXP_2,
      },
    },

    [states.DECLINED_AFTER_CUSTOMER_EXP_2]: {},
    [states.DECLINED_AFTER_PROVIDER_EXP_2]: {},
    [states.CANCELLED_EXP_2]: {},

    [states.DELIVERED_EXP]: {
      on: {
        [transitions.REVIEW_REMINDER_EXP_1]: states.DELIVERED_EXP,
        [transitions.REVIEW_REMINDER_EXP_2]: states.DELIVERED_EXP,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER_EXP]: states.REVIEWED_BY_CUSTOMER_EXP,
        [transitions.REVIEW_1_BY_PROVIDER_EXP]: states.REVIEWED_BY_PROVIDER_EXP,
      },
    },

    [states.REVIEWED_BY_CUSTOMER_EXP]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER_EXP]: states.REVIEWED_EXP,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD_EXP]: states.REVIEWED_EXP,
      },
    },
    [states.REVIEWED_BY_PROVIDER_EXP]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER_EXP]: states.REVIEWED_EXP,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD_EXP]: states.REVIEWED_EXP,
      },
    },
    [states.REVIEWED_EXP]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.OPERATOR_ACCEPT,
    transitions.CANCEL,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.CUSTOMER_DECLINE_BEFORE,
    transitions.CUSTOMER_DECLINE_AFTER,
    transitions.PROVIDER_DECLINE_BEFORE,
    transitions.PROVIDER_DECLINE_AFTER,
    transitions.REVIEW_REMINDER_1,
    transitions.REVIEW_REMINDER_2,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.PAY_EXP,
    transitions.PROVIDER_DECLINE_AFTER_EXP,
    transitions.CUSTOMER_DECLINE_AFTER_EXP,
    transitions.DECLINE_PROVIDER_AFTER_EXP_2,
    transitions.DECLINE_CUSTOMER_AFTER_EXP_2,
    transitions.CANCEL_EXP,
    transitions.CANCEL_EXP_2,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    transitions.PAYPAL_PAYMENT,
    transitions.STRIPE_PAYMENT,
    transitions.ENQUIRE_1,
    transitions.ENQUIRE_2,
  ].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.REVIEW_REMINDER_1,
    transitions.REVIEW_REMINDER_2,
  ];
  return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.DECLINE,
    transitions.CUSTOMER_DECLINE_BEFORE,
    transitions.PROVIDER_DECLINE_BEFORE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
