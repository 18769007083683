import React, { useEffect, useState, useMemo } from 'react';
import IconReviewStarMini from '../ReviewRatings/IconReviewStarMini/IconReviewStarMini';
import { transformGoogleReviews, transformReviews } from '../Utils/formatReviews.util';

const DEFAULT_TEXT_COLOR = 'black';

const ReviewRatings = ({
  currentListing,
  showDot = false,
  showText = true,
  textColor = DEFAULT_TEXT_COLOR,
  onShowModalFromOutside,
  isHostModal = false,
  starContainerClass = '',
  textSize,
  isSmall = true,
  fallBackReviews = [],
  setHasReviews,
}) => {
  const [reviewState, setReviewState] = useState({
    reviewsAmount: 0,
    finalRating: 0,
    reviews: null,
  });

  const { reviewsAmount, finalRating, reviews } = reviewState;

  useEffect(() => {
    if (!currentListing?.attributes?.publicData) {
      return;
    }

    const {
      finalReviews = 0,
      location = null,
      internalReviews = null,
      googleReviews: listingGoogleReviews = [],
    } = currentListing.attributes.publicData;

    const rawReviews = internalReviews?.reviews || [];
    const googleReviews = location?.reviews?.length ? location.reviews : listingGoogleReviews;

    setReviewState({
      reviews: [...rawReviews, ...googleReviews],
      finalRating: finalReviews,
      reviewsAmount: rawReviews.length + googleReviews.length,
    });
  }, [currentListing]);

  useEffect(() => {
    if (setHasReviews) {
      const hasValidReviews = reviews !== null && reviewsAmount > 0 && finalRating > 0;
      setHasReviews(hasValidReviews);
    }
  }, [reviews, reviewsAmount, finalRating, setHasReviews]);

  const renderReviewStars = useMemo(() => {
    const commonProps = {
      onShowModalFromOutside,
      starSize: !isHostModal,
      showDot,
      textColor: DEFAULT_TEXT_COLOR,
      isSmall,
      classNameContainer: starContainerClass,
      showText,
      textSize,
    };

    if (fallBackReviews.length > 0) {
      const formattedReviews = transformReviews(fallBackReviews);
      return (
        <IconReviewStarMini
          {...commonProps}
          value={formattedReviews.finalRating}
          reviews={formattedReviews.reviews}
          reviewsNumber={formattedReviews.reviewsAmount}
        />
      );
    }

    if (reviews?.length > 0) {
      const formattedReviews = transformGoogleReviews(reviews);
      return (
        <IconReviewStarMini
          {...commonProps}
          value={formattedReviews.finalRating}
          reviews={formattedReviews.reviews}
          reviewsNumber={formattedReviews.reviewsAmount}
        />
      );
    }

    return null;
  }, [
    reviews,
    fallBackReviews,
    onShowModalFromOutside,
    isHostModal,
    showDot,
    isSmall,
    starContainerClass,
    showText,
    textSize,
  ]);

  if (!renderReviewStars) {
    return null;
  }

  return <div>{renderReviewStars}</div>;
};

export default React.memo(ReviewRatings);
