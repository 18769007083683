import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import CategoryButtonsHero from './CategoryButtonsHero';
import ContentSection from './ContentSection';
import HeroImage from './HeroImage';
import css from './HeroSection.module.css';
const categories = ['experiences', 'stays', 'forYou'];

const MemoizedCategoryButtons = memo(CategoryButtonsHero);
const MemoizedContentSection = memo(ContentSection);

const HeroSection = ({ h1, h2 }) => {
  const history = useHistory();
  const location = useLocation();
  const [currentCategory, setCurrentCategory] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('category') || 'experiences';
  });

  const handleCategoryChange = React.useCallback(
    category => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('category', category);
      searchParams.set('type', "stays");

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });

      setCurrentCategory(category);
    },
    [history, location.pathname, location.search]
  );

  return (
    <div className={css.landinpageContainer}>
      <HeroImage currentCategory={currentCategory} header={h1} css={css}>
        <MemoizedCategoryButtons
          currentCategory={currentCategory}
          categories={categories}
          onCategoryChange={handleCategoryChange}
        />
      </HeroImage>
      <MemoizedContentSection currentCategory={currentCategory} subHeader={h2} />
    </div>
  );
};

HeroSection.propTypes = {
  h1: PropTypes.string.isRequired,
  h2: PropTypes.string.isRequired,
};

export default memo(HeroSection);
