import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const ContactUsSectionSkeleton = () => {
  return (
    <div
      style={{
        maxWidth: '1400px',
        margin: '1em auto',
        padding: '2em 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2em',
      }}
    >
      {/* Container for left image block and right text block */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '4em',
        }}
      >
        {/* Left image block skeleton */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          {/* Desktop images (simulate three images) */}
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Skeleton width={420} height={310} />
          </div>

          {/* Mobile: Profile image with text overlay */}
          <div style={{ textAlign: 'center' }}>
            <Skeleton circle width={250} height={250} />
            <Skeleton width={100} height={20} style={{ marginTop: '0.5rem' }} />
            <Skeleton width={120} height={20} style={{ marginTop: '0.5rem' }} />
          </div>

          {/* WhatsApp row + CTA button */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
            <Skeleton circle width={40} height={40} />
            <Skeleton width={150} height={20} />
          </div>
          <Skeleton width={300} height={40} style={{ marginTop: '1rem' }} />
        </div>

        {/* Right text block skeleton (desktop view) */}
        <div
          style={{
            maxWidth: '50%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Skeleton width={200} height={30} />
          <Skeleton width={'80%'} height={60} />
          <Skeleton width={300} height={40} />
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Skeleton circle width={40} height={40} />
            <Skeleton width={150} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSectionSkeleton;
