import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Phone from './icons/Phone';
import Xmark from './icons/Xmark';
import Email from './icons/Email';
import WhatsApp from './icons/Whatsapp';
import styles from './QuickMenu.module.css';
import imgWhatsapp from './images/whatsappIcon.webp';
import imgAlex from './images/01_Alex_orange.webp';

const QuickMenu = ({ isListingPage = false }) => {
  const [isSmallContact, setIsSmallContact] = useState(true);
  const [showQuickMenu, setShowQuickMenu] = useState(false);

  const ContactMenuContent = ({ onClose }) => (
    <div className={styles.quickMenuFullContainer}>
      <button className={styles.closeButton} onClick={onClose}>
        <Xmark />
      </button>
      <div>
        <div>
          <img src={imgAlex} alt="socialbnb member alex" width="100px" className={styles.image} loading="lazy"/>
          <div>
            <p className={styles.text}>
              <FormattedMessage id="landingPage.quikMenu.text1" />
              <FormattedMessage id="landingPage.quikMenu.text2" />
            </p>
            <p className={styles.text}></p>
            <a href="tel:+49 157 3097 76 60" className={styles.phoneNumberHref}>
              +49 157 3097 76 60
            </a>
          </div>

          <div className={styles.phoneEmailContainer}>
            <a href="mailto:booking@socialbnb.de">
              <Email width={28} height={28} />
            </a>
            <a href="https://wa.me/+4915730977660">
              <WhatsApp width={28} height={28} />
            </a>
            <a href="tel:+49 157 3097 76 60">
              <Phone width={28} height={28} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.container}>
        {isSmallContact ? (
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={() => setIsSmallContact(false)}>
              <div>
                <div>
                  <img src={imgAlex} className={styles.imageButton} alt="socialbnb member alex" />
                </div>
                <div className={styles.phoneIconContainer}>
                  <Phone />
                </div>
              </div>
            </button>
          </div>
        ) : (
          <ContactMenuContent onClose={() => setIsSmallContact(true)} />
        )}
      </div>

      {showQuickMenu && (
        <div className={styles.containerSecond}>
          <ContactMenuContent onClose={() => setShowQuickMenu(false)} />
        </div>
      )}

      {isListingPage && (
        <div className={styles.whatsappContainer}>
          <button
            className={styles.whatsappButton}
            onClick={() => setShowQuickMenu(!showQuickMenu)}
          >
            <img
              src={imgWhatsapp}
              alt="WhatsApp icon"
              width="100px"
              className={styles.whatsappImg}
            />
          </button>
        </div>
      )}
    </>
  );
};

export default QuickMenu;
