
import {
  natureIcon,
  farmIcon,
  familyIcon,
  islandsIcon,
  italyIcon,
  spainIcon,
  colombiaIcon,
  indonesiaIcon,
  ecuadorIcon,
  namibiaIcon,
  starIcon,
  activeIcon,
  europeIcon,
  asiaIcon,
  southAmericaIcon,
  animalsIcon,
  cultureIcon,
} from './categoryIcons';

export const multidayCategoryData = [
  {
    id: 1,
    messageId: 'landing.accomodations.categoryDefault',
    categoryIcon: starIcon,
    name: 'stays',
  },
  {
    id: 2,
    messageId: 'landing.accomodations.europe',
    categoryIcon: europeIcon,
    name: 'europe',
  },
  {
    id: 3,
    messageId: 'landing.accomodations.asia',
    categoryIcon: asiaIcon,
    name: 'asia',
  },
  {
    id: 4,
    messageId: 'landing.accomodations.southAmerica',
    categoryIcon: southAmericaIcon,
    name: 'south-america',
  },
  {
    id: 5,
    messageId: 'landing.accomodations.animal',
    categoryIcon: animalsIcon,
    name: 'animal',
  },
  {
    id: 6,
    messageId: 'landing.accomodations.category4',
    categoryIcon: natureIcon,
    name: 'nature',
  },
  {
    id: 7,
    messageId: 'landing.accomodations.active',
    categoryIcon: activeIcon,
    name: 'active',
  },
  {
    id: 8,
    messageId: 'landing.accomodations.culture',
    categoryIcon: cultureIcon,
    name: 'culture',
  },
];

export const uniqueAccomodationCategoryData = [
  {
    id: 0,
    messageId: 'landing.accomodations.categoryDefault',
    categoryIcon: starIcon,
    name: 'stays',
  },
  {
    id: 1,
    messageId: 'landing.accomodations.italy',
    categoryIcon: italyIcon,
    name: 'italy',
  },
  {
    id: 2,
    messageId: 'landing.accomodations.spain',
    categoryIcon: spainIcon,
    name: 'spain',
  },
  {
    id: 3,
    messageId: 'landing.accomodations.colombia',
    categoryIcon: colombiaIcon,
    name: 'colombia',
  },
  {
    id: 4,
    messageId: 'landing.accomodations.indonesia',
    categoryIcon: indonesiaIcon,
    name: 'indonesia',
  },
  {
    id: 5,
    messageId: 'landing.accomodations.ecuador',
    categoryIcon: ecuadorIcon,
    name: 'ecuador',
  },
  {
    id: 6,
    messageId: 'landing.accomodations.namibia',
    categoryIcon: namibiaIcon,
    name: 'namibia',
  },
  {
    id: 7,
    messageId: 'landing.accomodations.category8',
    categoryIcon: farmIcon,
    name: 'farm',
  },
  {
    id: 8,
    messageId: 'landing.accomodations.category4',
    categoryIcon: natureIcon,
    name: 'nature',
  },
  {
    id: 9,
    messageId: 'landing.accomodations.category2',
    categoryIcon: familyIcon,
    name: 'family',
  },
  {
    id: 10,
    messageId: 'landing.accomodations.category15',
    categoryIcon: islandsIcon,
    name: 'islands',
  },
];
