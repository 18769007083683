import React from 'react';
import { ForYouSection } from '../ForYouSection/ForYouSection';
import ExperienceList from '../ExperienceSection/ExperienceList';
import {
  multiDayCategoryDataKeys,
  uniqueAccomodationCategoryKeys,
} from '../ExperienceSection/data/ids';
import {
  multidayCategoryData,
  uniqueAccomodationCategoryData,
} from '../ExperienceSection/data/categoryData';

const ContentSection = ({ currentCategory, subHeader }) => {
  const categoryConfig = {
    stays: {
      ids: uniqueAccomodationCategoryKeys,
      categoryData: uniqueAccomodationCategoryData,
    },
    experiences: {
      ids: multiDayCategoryDataKeys,
      categoryData: multidayCategoryData,
    },
  };

  const renderExperienceList = category => {
    const config = categoryConfig[category];
    if (!config) return null;

    return (
      <div data-elbaction={config.dataElbAction}>
        <ExperienceList
          categoryTab={category}
          subHeader={subHeader}
          ids={config.ids}
          categoryData={config.categoryData}
        />
      </div>
    );
  };

  const contentData = {
    stays: renderExperienceList('stays'),
    experiences: renderExperienceList('experiences'),
    trips: [
      { title: 'Weekend Getaway', price: 'from 200€', location: 'Swiss Alps' },
      { title: 'Cultural Exploration', price: 'from 150€', location: 'Japan' },
      { title: 'Safari Adventure', price: 'from 300€', location: 'Kenya' },
    ],
    forYou: (
      <div>
        <ForYouSection />
      </div>
    ),
  };

  const content = contentData[currentCategory];

  if (Array.isArray(content)) {
    return (
      <div>
        {content.map((item, index) => (
          <div key={index}>
            <h3>{item.title}</h3>
            <p>{item.price}</p>
            <p>{item.location}</p>
          </div>
        ))}
      </div>
    );
  }

  return <div>{content}</div>;
};

export default ContentSection;
