import React from 'react';
import {
  familyIcon,
  educationIcon,
  environmentIcon,
  equalityIcon,
  healthIcon,
  sportsIcon,
  elephantIcon,
} from '../data/categoryIcons';

const charValues = [
  {
    value: 'Tierschutz',
    icon: elephantIcon,
  },
  {
    value: 'Naturschutz',
    icon: environmentIcon,
  },
  {
    value: 'Equality',
    icon: equalityIcon,
  },
  {
    value: 'Health',
    icon: healthIcon,
  },
  {
    value: 'Sports',
    icon: sportsIcon,
  },
  {
    value: 'Bildung',
    icon: educationIcon,
  },
  {
    value: 'Familie',
    icon: familyIcon,
  },
];

export const ProjectIcon = React.memo(({ type }) => {
  if (!type) return null;

  const charMatch = charValues.find(char => char.value === type);
  if (charMatch)
    return <img src={charMatch.icon} style={{ height: '27px', width: '27px' }} alt={type} />;
});
