import { func, shape } from 'prop-types';
import {
  IntlProvider,
  FormattedMessage,
  FormattedDate,
  FormattedDateTimeRange,
  createIntl,
  createIntlCache,
  injectIntl,
  useIntl,
} from 'react-intl';

import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-gb'; // Import en-GB locale
import 'moment/locale/de'; // Import de locale
import { useEffect } from 'react';
import englishMessages from '../translations/en.json';
import germanMessages from '../translations/de.json';

// Note: we import React Intl directly from dist directory, because
// by default the library assumes the usage of ES modules
// and that don't work with server-side rendering.
// https://github.com/formatjs/formatjs/issues/1499#issuecomment-570151879

// TODO - these we can use later in case we want to keep all the translations in one file
// const filterMessages = (jsonData, language) => {
//   const originalKeys = Object.keys(jsonData);
//   const filteredKeys = originalKeys.filter(k => {
//     const lastThreeCharacters = k.substring(k.length - 3);
//     return lastThreeCharacters === `.${language}`;
//   });

//   const filteredData = {};

//   filteredKeys.forEach(key => {
//     filteredData[key] = jsonData[key];
//   });

//   return filteredData;
// };

// const mapMessages = (jsonData, language) => {
//   const originalKeys = Object.keys(jsonData);
//   const mappedKeys = originalKeys.map(k => k.substring(0, k.length - 3));

//   const mappedData = {};

//   mappedKeys.forEach(key => {
//     mappedData[key] = jsonData[`${key}.${language}`];
//   });

//   return mappedData;
// };
const ReduxConnectedIntlProviderComponent = props => {
  const { children, language, ...rest } = props;
  // TODO - these we can use later in case we want to keep all the translations in one file

  // const filteredMessages = filterMessages(messages, language);
  // const mappedMessages = mapMessages(filteredMessages, language);

  const messages = language === 'en' ? englishMessages : germanMessages;

  useEffect(() => {
    moment.locale(language);
  }, [language]);
  return (
    <IntlProvider {...rest} locale={language} messages={messages}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = state => {
  const { language } = state.ui;

  return {
    language,
  };
};
const ReduxConnectedIntlProvider = compose(connect(mapStateToProps))(
  ReduxConnectedIntlProviderComponent
);

const intlShape = shape({
  formatDate: func.isRequired,
  formatDateTimeRange: func.isRequired,
  formatMessage: func.isRequired,
  formatNumber: func.isRequired,
  formatPlural: func.isRequired,
  formatRelativeTime: func.isRequired,
  formatTime: func.isRequired,
});
export {
  IntlProvider,
  ReduxConnectedIntlProvider,
  FormattedMessage,
  FormattedDate,
  FormattedDateTimeRange,
  createIntl,
  createIntlCache,
  injectIntl,
  useIntl,
  intlShape,
};
