// ElbwalkerInitializer.js
import { useEffect } from 'react';
import { setupWalkerjs } from './walker';
import { elb } from '@elbwalker/utils';
// Falls du auf den aktuellen Pfad reagieren möchtest:
// import { useLocation } from 'react-router-dom';

//Initializing Walker.js only once!
setupWalkerjs();

const ElbwalkerInitializer = () => {
  // Wenn du Routenwechsel tracken möchtest, könntest du useLocation nutzen.
  // Da du den Session-Start aber nur einmal ausführen willst, brauchst du das nicht.
  useEffect(() => {
    setTimeout(() => {
      elb('walker run');
      async function initUserId() {
        const utils = await import('@elbwalker/utils');
        const key = 'elbUserId';
        // Lies den Wert aus dem localStorage:
        let id = utils.storageRead(key, 'local') || 
                 utils.storageWrite(key, utils.getId(5), 60 * 60 * 24 * 365, 'local'); // z.B. mit 1 Jahr als Gültigkeitsdauer
        elb('walker user', { id });
      }
      initUserId();

      elb('walker session start');
    }, 1000);
  }, []); // Nur einmal beim Mounten

  return null;
};

export default ElbwalkerInitializer;
