import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Bookings.module.css';
import ExperienceListCategories from './components/ExperienceListCategories';
import ExperienceCard from './components/ExperienceCard';
import { useDeviceSize } from './hooks/useDeviceSize';
import { showFavoriteListing } from '../../../ListingPage/ListingPage.duck';
import ExperiencesListSkeleton from '../Skeletons/ExperiencesListSkeleton';
import { connect } from 'react-redux';
import { formatListing } from './utils/formating';
import { useParams } from 'react-router-dom';

const ExperienceListComponent = ({ subHeader, ids, categoryData, onFetchListing, categoryTab }) => {
  const [selectedCategory, setSelectedCategory] = useState('stays');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isMobileDevice } = useDeviceSize();
  const { lang: currentLanguage } = useParams();

  const getDisplayedName = useCallback(
    item => {
      const nameToUse = currentLanguage === 'de' && item?.name_de ? item?.name_de : item?.name;
      return nameToUse?.split(' • ')[0] ?? nameToUse ?? '';
    },
    [currentLanguage]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        let filteredIds = [];
        if (!selectedCategory) return;

        if (
          [
            'europe',
            'asia',
            'south-america',
            'italy',
            'spain',
            'colombia',
            'indonesia',
            'ecuador',
            'namibia',
          ].includes(selectedCategory)
        ) {
          filteredIds = ids.filter(id => id.location === selectedCategory);
        } else {
          filteredIds = ids.filter(id => id.projectType.includes(selectedCategory));
        }

        const data = await Promise.all(
          filteredIds.map(filteredId =>
            onFetchListing(filteredId.id).then(({ data }) => formatListing(data, currentLanguage))
          )
        );

        setData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentLanguage, selectedCategory, ids]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>
          <FormattedMessage id={subHeader} />
        </h2>
        <ExperienceListCategories
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isMobileDevice={isMobileDevice}
          categoryData={categoryData}
          categoryTab={categoryTab}
        />
        <div className={styles.AllCards}>
          {isLoading ? (
            <ExperiencesListSkeleton />
          ) : error ? null : (
            <>
              {data.map((item, index) => (
                <ExperienceCard
                  key={item.id || index} // Fallback auf index, falls item.id nicht vorhanden ist
                  item={item}
                  shortName={getDisplayedName(item)}
                  currentLanguage={currentLanguage}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onFetchListing: listingId =>
    dispatch(showFavoriteListing(listingId, { layout: { listingImage: {} } })),
});

export const ExperienceList = connect(null, mapDispatchToProps)(ExperienceListComponent);

export default ExperienceList;
