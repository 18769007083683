import React, { useEffect, useRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useTouchScroll } from '../hooks/useTouchScroll';
import styles from '../Bookings.module.css';

const ExperienceListCategories = ({
  selectedCategory,
  setSelectedCategory,
  isMobileDevice,
  categoryData,
  categoryTab,
}) => {
  const history = useHistory();
  const location = useLocation();
  const sliderRef = useRef(null);

  const handleCategoryClick = useCallback(
    category => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('type', category.name);
      setSelectedCategory(category.name);

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search, setSelectedCategory]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeFromUrl = searchParams.get('type');

    if (typeFromUrl) {
      const selectedType = categoryData.find(item => item.name === typeFromUrl);
      if (selectedType) {
        setSelectedCategory(selectedType.name);
      }
    }
  }, [location.search, setSelectedCategory]);

  useTouchScroll(sliderRef, isMobileDevice);

  return (
    <div className={isMobileDevice ? styles.mobileCategories : styles.desktopCategories}>
      <div className={isMobileDevice ? styles.mobileSlider : styles.desktopSlider} ref={sliderRef}>
        {categoryData.map((category, index) => (
          <React.Fragment key={category.id}>
            <button
              id={category.id}
              className={
                selectedCategory === category.name
                  ? styles.categoryButtonActive
                  : styles.categoryButton
              }
              onClick={() => handleCategoryClick(category)}
              data-elbaction={
                categoryTab == 'stays'
                  ? `click:click lp-impctcat-list-${category.name}`
                  : `click:click lp-impctcat-exp-${category.name}`
              }
            >
              <img
                src={category.categoryIcon}
                className={styles.categoryIcon}
                alt={`${category.name} icon`}
              />
              <div className={styles.categoryLabel}>
                <span className={styles.categoryText}>
                  <FormattedMessage id={category.messageId} />
                </span>
              </div>
            </button>
            {!isMobileDevice &&
              (index === 0 ||
                (categoryData.length <= 10 && index === 3) ||
                (categoryData.length >= 9 && index === 6)) && (
                <div className={styles.separator}>&nbsp;</div>
              )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ExperienceListCategories;
