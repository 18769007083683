import React from 'react';
import { FormattedMessage } from 'react-intl';
import NamedLinkNewTab from '../../../../../components/NamedLink/NamedLinkNewTab';
import IconReviewSearchPage from '../../../../ListingPage/ListingPageComponents/ReviewRatings/IconReviewSearchPage/iconReviewSearchPage';
import { ProjectIcon } from './ProjectIcon';
import Pin_Icon from '../../../../../assets/Pin_Icon.svg';
import styles from '../Bookings.module.css';

const ExperienceCard = ({ item, shortName, currentLanguage }) => {
  if (!item) return null;

  const listingId = item.link?.substring(item.link?.lastIndexOf('/') + 1);
  const projectTypes = Array.isArray(item.projectType) ? item.projectType : [];

  return (
    <NamedLinkNewTab name={item.linkName} params={item.linkParams}>
      <div
        className={styles.oneCard}
        data-elbaction={
          item?.isExperience
            ? `click:click lp-impct-exp-${shortName}-uuid-${listingId}`
            : `click:click lp-impct-list-${shortName}-uuid-${listingId}`
        }
      >
        <img className={styles.img} src={item.image} alt="socialbnb impact accommodation" />
        <div className={styles.spanDescription}>
          <div className={styles.listDescriptionWrapper}>
            <p className={styles.descriptionTxt}>
              <span className={styles.name}>{shortName}</span>
              <span className={styles.priceSubtitle}>
                {item?.isExperience ? (
                  <>{currentLanguage === 'de' ? item?.stayPriceRangeDe : item?.stayPriceRange}</>
                ) : (
                  <>
                    <span className={styles.price}>{item.price}</span>
                    <FormattedMessage id="landing.accomodations.pernight" />
                  </>
                )}
              </span>
            </p>
          </div>

          <div className={styles.pinlogo}>
            <div>
              <img
                src={Pin_Icon}
                width="18px"
                style={{ paddingRight: '2px' }}
                alt="socialbnb impact icon"
              />
              <span className={styles.location}>
                {currentLanguage === 'de' ? item.location_de : item.location}
              </span>
            </div>
            {item.finalReviews > 0 ? (
              <div className={styles.projectRatings}>
                <IconReviewSearchPage
                  averageRating={item.finalReviews}
                  showDot={false}
                  textColor="black"
                />
              </div>
            ) : (
              <div className={styles.emptySpacer} />
            )}
          </div>

          <div className={styles.projectype}>
            <div className={styles.projectImg}>
              {projectTypes[0] && (
                <span className={styles.projectImg1}>
                  <ProjectIcon type={projectTypes[0]} />
                </span>
              )}
              {projectTypes[1] && (
                <span className={styles.projectImg2}>
                  <ProjectIcon type={projectTypes[1]} />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </NamedLinkNewTab>
  );
};

export default ExperienceCard;
