// ConsentLoader.js
import { useEffect } from 'react';

function ConsentLoader() {
  useEffect(() => {
    const checkHubspot = setInterval(() => {
      if (typeof window !== 'undefined' && window._hsp) {
        clearInterval(checkHubspot);
        window._hsp = window._hsp || [];
        window._hsp.push([
          'addPrivacyConsentListener',
          function(consent) {
            if (consent.categories && consent.categories.analytics) {
              // Consent Update senden, wenn Analytics zugestimmt wird:
              function gtag() {
                window.dataLayer.push(arguments);
              }
              gtag('consent', 'update', {
                analytics_storage: 'granted',
                ad_storage: 'granted'
              });
              // GA konfigurieren und explizit erlauben, dass personalisierte Anzeigen gesendet werden
              gtag('config', 'G-F2LBJPDPKK', {
                allow_ad_personalization_signals: true
              });
            } else {
              console.log('Analytics-Cookies wurden nicht zugelassen.');
            }
          }
        ]);
      }
    }, 500);

    return () => clearInterval(checkHubspot);
  }, []);

  return null;
}

export default ConsentLoader;
