import { useState, useEffect } from 'react';

export const useDeviceSize = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMiddleDevice, setIsMiddleDevice] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      setIsMobileDevice(screenSize < 1025);
      setIsMiddleDevice(screenSize < 1400);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobileDevice, isMiddleDevice };
};
